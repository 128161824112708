import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSoccerBall, faCalendar, faTableCells, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import Modal from './components/Modal'
import { selectFirstVisit } from './game'

const HowToPlay = () => {
  const firstVisit = useSelector(selectFirstVisit)
  const [open, setOpen] = useState(firstVisit) 

  return (
    <div>
      <FontAwesomeIcon className="hover:cursor-pointer" title="How to play" icon={faQuestionCircle} onClick={() => setOpen(true)} />
      <Modal title="How to play" open={open} onClose={() => setOpen(false)}>
        <ol className="grid grid-cols-1 w-full gap-4">
          <li className="flex items-start">
            <FontAwesomeIcon icon={faSoccerBall} className="mt-1 mr-3" />
            <p>Look at the English football results and league table.</p>
          </li>
          <li className="flex items-start">
            <FontAwesomeIcon icon={faCalendar} className="mt-1 mr-3" />
            <p>Try to guess the date based on the results, teams, and positions.</p>
          </li>
          <li className="flex items-start">
            <FontAwesomeIcon icon={faTableCells} className="mt-1 mr-3" />
            <p>
              Go to the Guess panel and enter the month and year.
              The cell of the guess is colored 🟩 when the digit is in the correct position,
              🟨 when the digit is present but in the wrong position
              and ⬛ when the digit is not present.
            </p>
          </li>
          <li className="flex items-start">
            <FontAwesomeIcon icon={faShareNodes} className="mt-1 mr-3" />
            <p>Guess the date in as few attempts as possible and share your result!</p>
          </li>
        </ol>
      </Modal>
    </div>
  )
}

export default HowToPlay