import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Modal from './components/Modal'

const About = () => {
  const [open, setOpen] = useState(false) 

  return (
    <div>
      <FontAwesomeIcon className="hover:cursor-pointer" title="About" icon={faInfoCircle} onClick={() => setOpen(true)} />
      <Modal title="About" open={open} onClose={() => setOpen(false)}>
        <div className="grid grid-cols-1 gap-4">
        <p>
          A game inspired by <a href="https://www.nytimes.com/games/wordle/index.html">Wordle</a>
          , <a href="https://worldle.teuteuf.fr/">Worldle</a>
          , <a href="https://www.heardle.app/">Heardle</a>
          , <a href="https://framed.wtf/">Framed</a> and <a href="https://medium.com/floodgates/the-complete-and-authoritative-list-of-wordle-spinoffs-fb00bfafc448">all the other great spin-offs</a>.
        </p>
        <p>
          The historical football data comes from the excellent <a href="https://www.englishfootballleaguetables.co.uk/">English Football League Tables</a>.
        </p>
        <p>
          The application is built with <a href="https://reactjs.org/">React</a>
          , <a href="https://redux.js.org/">Redux</a>
          , <a href="https://tailwindcss.com/">Tailwind</a>
          , <a href="https://fontawesome.com/">Font Awesome</a>, and powered by <a href="https://vercel.com/">Vercel</a>.
          The version is {process.env.REACT_APP_VERSION}.
        </p>
      </div>
      </Modal>
    </div>
  )
}

export default About