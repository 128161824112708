import React from 'react'
import classNames from 'classnames'
import { Tab } from '@headlessui/react'
import DarkModeToggle from './DarkModeToggle'
import About from './About'
import HowToPlay from './HowToPlay'
import ResultsPanel from './ResultsPanel'
import TablePanel from './TablePanel'
import GuessPanel from './GuessPanel'

const App = () => {
  const panels = [
    {
      name: 'Results',
      panel: <ResultsPanel />
    },
    {
      name: 'Table',
      panel: <TablePanel />
    },
    {
      name: 'Guess',
      panel: <GuessPanel />
    }
  ]

  const getTabClassName = (selected) => {
    return classNames('p-2', 'rounded',
      'text-white', 'hover:bg-emerald-700', 'text-xl',
      {
        'bg-emerald-700': selected,
        'bg-neutral-500': !selected
      })
  }

  return (
    <div className="flex flex-col h-full overflow-auto font-mono dark:bg-black dark:text-white">
      <div className="flex p-4 justify-center border-b border-neutral-500">
        <header className="flex w-full justify-between items-center text-3xl sm:w-[600px]">
          <h1 className="font-bold">Leagdle</h1>
          <div className="flex gap-2">
            <DarkModeToggle />
            <About />
            <HowToPlay />
          </div>
        </header>
      </div>
      <div className="flex flex-1 p-4 justify-center">
          <main className="flex flex-col w-full h-full gap-6 items-center sm:w-[600px]">
            <Tab.Group>
              <Tab.List className="flex gap-2">
                {
                  panels.map((p, index) => (
                    <Tab key={index} className={({selected}) => getTabClassName(selected)}>
                      {p.name}
                    </Tab>
                  ))
                }
              </Tab.List>
              <Tab.Panels className="flex flex-1 w-full">
              {
                  panels.map((p, index) => (
                    <Tab.Panel key={index} className="flex w-full h-full">
                      {p.panel}
                    </Tab.Panel>
                  ))
                }
              </Tab.Panels>
            </Tab.Group>
          </main>
      </div>
    </div>
  )
}

export default App