import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons'
import { selectDarkMode, toggleDarkMode } from './game'
import useBodyClass from './components/useBodyClass'

const DarkModeToggle = () => {
  const darkMode = useSelector(selectDarkMode)
  useBodyClass(darkMode ? 'dark' : 'light')

  const dispatch = useDispatch()

  return (
    <FontAwesomeIcon
      className="hover:cursor-pointer"
      title="Dark mode"
      icon={darkMode ? faToggleOn : faToggleOff}
      onClick={() => dispatch(toggleDarkMode())}
    />
  )
}

export default DarkModeToggle