import React, { useRef } from 'react'
import { Dialog } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'

const Modal = ({ title, open, onClose, children }) => {
  const initialFocusRef = useRef(null)

  return (
    <Dialog className="relative z-50" initialFocus={initialFocusRef} open={open} onClose={onClose}>
      <div className="fixed inset-0 bg-white/70 dark:bg-black/70" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm p-4 rounded border border-black bg-white text-black dark:bg-black dark:text-white dark:border-white">
          <div className="grid grid-cols-1 gap-6" ref={initialFocusRef}>
            <div className="flex justify-between">
              <Dialog.Title className="text-2xl">{title}</Dialog.Title>
              <FontAwesomeIcon className="hover:cursor-pointer" icon={faX} onClick={onClose} />
            </div>
            <div>{children}</div>
          </div>   
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default Modal