import React from 'react'

const ResultList = ({ results }) => {
  return (
    <div className="flex flex-col w-full gap-4">
      {
        results.map((result, index) => (
          <Result key={index} result={result} />
        ))
      }
    </div>
  )
}

const Result = ({ result }) => {
  return (
    <div className="flex flex-row w-full">
      <TeamResult teamResult={result.home} />
      <Score home={result.home.score} away={result.away.score} />
      <TeamResult teamResult={result.away} />
    </div>    
  )
}

const Score = ({ home, away }) => {
  return (
    <div className="basis-2/12">
      <span>{home}</span>
      <span>-</span>
      <span>{away}</span>
    </div>
  )
}

const TeamResult = ({ teamResult }) => {
  return (
    <div className="basis-5/12">
      <span className="font-bold uppercase dark:text-[#00ffff]">{teamResult.team}</span>
      <ul>
        {
          teamResult.scorers.map((scorer, index) => (
            <li key={index}>{scorer}</li>
          ))
        }
      </ul>
    </div>
  )
}

export default ResultList