import React from 'react'
import classNames from 'classnames'
import { Transition } from '@headlessui/react'
import { CELL_STATE } from '../game'

const GuessGrid = ({ guesses, maxGuesses, input }) => {
  const success = guesses.some(g => g.success)

  const createCell = (i, j) => {
    if (i < guesses.length) {
      const { cells } = guesses[i]
      const { value, state } = cells[j]

      const enter = classNames('transition-color', 'duration-200',
        {
          'delay-[0ms]': j === 0,
          'delay-[200ms]': j === 1,
          'delay-[400ms]': j === 2,
          'delay-[600ms]': j === 3,
          'delay-[800ms]': j === 4,
          'delay-[1000ms]': j === 5
        })

      const enterTo = classNames({
        'bg-green-600': state === CELL_STATE.CORRECT,
        'bg-yellow-600': state === CELL_STATE.PRESENT,
        'bg-neutral-600': state === CELL_STATE.MISSING
      })
      
      return (
        <Transition
          className="w-full rounded"
          appear={true}
          show={true}
          enter={enter}
          enterFrom="bg-neutral-900"
          enterTo={enterTo}
        >
          <GuessCell value={value} />
        </Transition>
      )
    }
    else {
      const value = i === guesses.length && j < input.length ? input[j] : ''
      const placeholder = j > 1 ? 'Y' : 'M'
      const focused = !success && i === guesses.length && j === input.length

      return (
        <InputCell value={value} placeholder={placeholder} focused={focused} />
      )
    }
  }

  return (
    <div className="grid grid-cols-6 w-full max-w-[300px] gap-2 sm:max-w-[400px]">
      {
        [...Array(maxGuesses).keys()].map(i => 
          [...Array(6).keys()].map(j => (
            <div className="flex aspect-square" key={(i * 6) + j}>
              {createCell(i, j)}
            </div>
        )))
      }
    </div>
  )
}

const GuessCell = ({ value }) => {
  const className = classNames('flex', 'w-full', 'h-full',
    'justify-center', 'items-center', 'rounded',
    'text-white', 'text-2xl', 'font-bold'
  )

  return (
    <div className={className}>{value}</div>
  )
}

const InputCell = ({ value, placeholder, focused }) => {
  const className = classNames('w-full', 'h-full', 'rounded',
    'text-center', 'text-black', 'text-2xl', 'font-bold',
    {
      'border': !focused,
      'border-neutral-500': !focused,
      'border-2': focused,
      'border-amber-500': focused
    })

  return (
    <input
      className={className}
      type="text"
      readOnly
      value={value}
      placeholder={placeholder} />
  )
}

export default GuessGrid