import React, { useEffect } from 'react'

const Keyboard = ({ onDigit, onDelete, onEnter }) => {
  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  })

  const handleKeyUp = (event) => {    
    const { key } = event

    if (isFinite(key)) {
      const digit = parseInt(key)
      onDigit(digit)
    }
    else if (key === 'Backspace' || key === 'Delete') {
      onDelete()
    }
    else if (key === 'Enter') {
      onEnter()
    }
  }

  return (
    <div className="grid grid-cols-6 w-full max-w-[300px] p-2 gap-2 rounded bg-neutral-400 sm:max-w-[400px]">
      <Key value={1} onPress={() => onDigit(1)} />
      <Key value={2} onPress={() => onDigit(2)} />
      <Key value={3} onPress={() => onDigit(3)} />
      <Key value={4} onPress={() => onDigit(4)} />
      <Key value={5} onPress={() => onDigit(5)} />
      <Key value={'\u232b'} onPress={onDelete} />
      <Key value={6} onPress={() => onDigit(6)} />
      <Key value={7} onPress={() => onDigit(7)} />
      <Key value={8} onPress={() => onDigit(8)} />
      <Key value={9} onPress={() => onDigit(9)} />
      <Key value={0} onPress={() => onDigit(0)} />
      <Key value={'\u23ce'} onPress={onEnter} />
    </div>
  )
}

const Key = ({value, onPress}) => {
  return (
    <button className="flex rounded aspect-square justify-center items-center bg-neutral-300 text-black text-xl font-bold" onClick={onPress}>
      {value}
    </button>
  )
}

export default Keyboard
