import React from 'react'
import classNames from 'classnames'

const GameDate = ({ date, hide }) => {
  const className = classNames({
    'bg-black dark:bg-white': hide
  })

  return (
    <span>
      <span>{date.getDate()}</span>
      <span>{dayOfMonthSuffix(date.getDate())}</span>
      <span>&nbsp;</span>
      <span className={className}>{monthName(date.getMonth())}</span>
      <span>&nbsp;</span>
      <span className={className}>{date.getFullYear()}</span>
    </span>
  )
}

const dayOfMonthSuffix = (day) => {
  switch (day) {
    case 1:
    case 21:
    case 31:
      return 'st'
    case 2:
    case 22:
      return 'nd'
    default:
      return 'th'
  }
}

const monthName = (month) => {
  switch (month) {
    case 0:
      return 'Jan'
    case 1:
      return 'Feb'
    case 2:
      return 'Mar'
    case 3:
      return 'Apr'
    case 4:
      return 'May'
    case 5:
      return 'Jun'
    case 6:
      return 'Jul'
    case 7:
      return 'Aug'
    case 8:
      return 'Sep'
    case 9:
      return 'Oct'
    case 10:
      return 'Nov'
    case 11:
      return 'Dec'
    default:
      return ''
  }
}

export default GameDate