import { createSlice, configureStore, createSelector } from '@reduxjs/toolkit'
import games from './games.json'

// The day of the first game
const startDate = new Date(2022, 5, 24)

// Today at midnight
const currentDayMidnight = new Date()
currentDayMidnight.setHours(0, 0, 0, 0)

// Number of milliseconds in a single day
const millisecondsPerDay = 24 * 60 * 60 * 1000

// Calculate how many days have passed since the first game
const elapsedMilliseconds = currentDayMidnight - startDate
const elapsedDays = Math.round(elapsedMilliseconds / millisecondsPerDay)

// The current game number
const gameNumber = elapsedDays + 1

// The current game
const game = games[(gameNumber - 1) % games.length]

// The game details
const { date, results, table } = game

// The time when the next game begins
const nextGameTime = new Date(currentDayMidnight.getTime() + millisecondsPerDay)

// The solution is the month and year part of the date
const solution = [5, 6, 0, 1, 2, 3]
  .map(index => date[index])
  .map(c => parseInt(c))

// The slice of state holding the current game number
const gameNumberSlice = createSlice({
  name: 'gameNumber',
  initialState: gameNumber,
  reducers: {}
})

// The slice of state holding the guesses
const guessesSlice = createSlice({
  name: 'guesses',
  initialState: [],
  reducers: {
    addGuess: (state, action) => {
      state.push(action.payload)
    }
  }
})

// The default settings for the game
const defaultSettings = {
  darkMode: true
}

// The slice of state holding the game settings
const settingsSlice = createSlice({
  name: 'settings',
  initialState: defaultSettings,
  reducers: {
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode
    }
  }
})

// Action for adding a guess
const { addGuess } = guessesSlice.actions

// Action for toggling dark mode
const { toggleDarkMode } = settingsSlice.actions

// The maximum number of allowed guesses
const MAX_GUESSES = 6

// The different states for a guess cell
const CELL_STATE = {
  CORRECT: 0,
  PRESENT: 1,
  MISSING: 2
}

// Evaluate a guess
const evaluate = (input) => {
  // First iteration to check for correct position
  const [correct, remaining, success] = input.reduce(([c, r, s], value, i) => {
    if (value === solution[i]) {
      c.push(true)
      return [c, r, s]
    }
    else {
      c.push(false)
      r.push(solution[i])
      return [c, r, false]
    }
  }, [[], [], true])

  // Second iteration to check for wrong position or missing
  const cells = input.map((value, i) => {
    if (correct[i]) {
      return {
        value,
        state: CELL_STATE.CORRECT
      }
    }
    else {
      const index = remaining.indexOf(value)

      if (index >= 0) {
        remaining.splice(index, 1)
        return {
          value,
          state: CELL_STATE.PRESENT
        }
      }
      else {
        return {
          value,
          state: CELL_STATE.MISSING
        }
      }
    }
  })

  return {
    cells,
    success
  }
}

// Action for submitting guess input
const submitGuessInput = (input) => (dispatch, getState) => {
  const state = getState()
  const guesses = selectGuesses(state)

  if (guesses.length < MAX_GUESSES) {
    const guess = evaluate(input)
    dispatch(addGuess(guess))
  }
}

// Retrieve any persisted game state in the user's local storage
const persistedState = localStorage.getItem('gameState') && JSON.parse(localStorage.getItem('gameState'))

// Is it the user's first visit to the site
const firstVisit = !persistedState

// Build the initial state
const initialState = {
  gameNumber,
  guesses: persistedState && persistedState.gameNumber === gameNumber ? persistedState.guesses : [],
  settings: persistedState && persistedState.settings ? persistedState.settings : defaultSettings
}

// The store
const store = configureStore({
  reducer: {
    gameNumber: gameNumberSlice.reducer,
    guesses: guessesSlice.reducer,
    settings: settingsSlice.reducer
  },
  preloadedState: initialState
})

// When the game state changes, save it back to local storage
store.subscribe(() => {
  localStorage.setItem('gameState', JSON.stringify(store.getState()))
})

// Select if is the user's first visit to site
const selectFirstVisit = () => firstVisit

// The date to guess
const selectDate = () => new Date(date)

// The league table
const selectTable = () => table

// The results
const selectResults = () => results

// Select all guesses
const selectGuesses = state => state.guesses

// Select success
const selectSuccess = createSelector(selectGuesses, guesses => guesses.some(guess => guess.success))

// Select game over
const selectGameOver = createSelector(selectSuccess, selectGuesses, (success, guesses) => success || guesses.length >= MAX_GUESSES)

// Select the share text
const selectShareText = createSelector(selectSuccess, selectGuesses, (success, guesses) => {
  const title = `Leagdle #${gameNumber} ${success ? guesses.length : 'X'}/${MAX_GUESSES} ${success ? '🏆' : ''}`
  
  const rows = guesses.map(guess => {
    return guess.cells.map(({ state }) => {
      switch (state) {
        case CELL_STATE.CORRECT:
          return '🟩'
        case CELL_STATE.PRESENT:
          return '🟨'
        case CELL_STATE.MISSING:
        default:
          return '⬜️'
      }
    }).join('')
  })

  const url = 'https://leagdle.app'

  return [title, ...rows, url].join('\n')
})

// The next game time
const selectNextGameTime = () => nextGameTime

// The dark model
const selectDarkMode = state => state.settings.darkMode

export {
  store,
  CELL_STATE,
  MAX_GUESSES,
  toggleDarkMode,
  submitGuessInput,
  selectFirstVisit,
  selectDate,
  selectResults,
  selectTable,
  selectGuesses,
  selectSuccess,
  selectGameOver,
  selectShareText,
  selectNextGameTime,
  selectDarkMode
}