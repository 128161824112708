import React from 'react'
import { useSelector } from 'react-redux'
import { selectResults} from './game'
import ResultList from './components/ResultList'

const ResultsPanel = () => {
  const results = useSelector(selectResults)

  return (
    <div className="flex w-full">
      <ResultList results={results} />
    </div>
  )
}

export default ResultsPanel