import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectGameOver, selectSuccess, selectDate, selectGuesses, selectShareText, submitGuessInput, selectNextGameTime, MAX_GUESSES } from './game'
import GameDate from './components/GameDate'
import Keyboard from './components/Keyboard'
import CopyButton from './components/CopyButton'
import Countdown from './components/Countdown'
import GuessGrid from './components/GuessGrid'

const GuessPanel = () => {
  const [guessInput, setGuessInput] = useState([])
  const dispatch = useDispatch()

  const date = useSelector(selectDate)
  const gameOver = useSelector(selectGameOver)
  const success = useSelector(selectSuccess)
  const guesses = useSelector(selectGuesses)
  const shareText = useSelector(selectShareText)
  const nextGameTime = useSelector(selectNextGameTime)

  const handleDigit = (digit) => {
    if (guessInput.length < 6) {
      setGuessInput([...guessInput, digit])
    }
  }

  const handleDelete = () => {
    if (guessInput.length > 0) {
      const temp = guessInput.slice(0, guessInput.length - 1)
      setGuessInput(temp)
    }
  }

  const handleEnter = () => {
    if (guessInput.length === 6) {
      dispatch(submitGuessInput(guessInput))
      setGuessInput([])
    }
  }

  const getMessage = () => {
    if (gameOver) {
      if (success) {
        return '⚽ Back of the net!'
      }
      else {
        return 'Hard luck!'
      }
    }
    else {
      return 'Guess the month and year'
    }
  }

  return (
    <div className="flex flex-col w-full h-full gap-4">
      <div className="flex flex-col grow gap-4 items-center">
        <div className="flex flex-col items-center">
          <h2>{getMessage()}</h2>
          <GameDate date={date} hide={!gameOver} />
        </div>
        <GuessGrid guesses={guesses} maxGuesses={MAX_GUESSES} input={guessInput} />
        {
          gameOver && <CopyButton text={shareText} label="Share" copiedLabel="Copied" />
        }
        {
          gameOver && <div>Next game in <Countdown toDate={nextGameTime} /></div>
        }
      </div>
      <div className="flex justify-center">
        {
          gameOver || <Keyboard onDigit={handleDigit} onDelete={handleDelete} onEnter={handleEnter} />
        }
      </div>
    </div>
  )
}

export default GuessPanel