import React from 'react'
import { useSelector } from 'react-redux'
import { selectTable } from './game'
import Table from './components/Table'

const TablePanel = () => {
  const table = useSelector(selectTable)

  return (
    <div className="flex w-full">
      <Table items={table} />
    </div>
  )
}

export default TablePanel