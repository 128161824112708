import React from 'react'

const Table = ({ items }) => {
  return (
    <table className="table table-auto w-full">
      <thead>
        <tr>
          <th className="text-left">Pos</th>
          <th className="text-left">Team</th>
          <th className="text-right">P</th>
          <th className="text-right">W</th>
          <th className="text-right">D</th>
          <th className="text-right">L</th>
          <th className="text-right">Pts</th> 
        </tr>    
      </thead>
      <tbody>
        {
          items.map(([position, team, p, w, d, l, points], index) => (
            <tr key={index} className="dark:even:text-[#00ffff]">
              <td className="text-left">{position}</td>
              <td className="text-left">{team}</td>
              <td className="text-right">{p}</td>
              <td className="text-right">{w}</td>
              <td className="text-right">{d}</td>
              <td className="text-right">{l}</td>
              <td className="text-right">{points}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default Table